<template>
  <div class="cbox" style="height: 100%;">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->

        <tchtree v-model="class_id" @change="getData" ></tchtree>
        <span style="margin-left:20px">选择月：</span>
        <!--        <el-date-picker  @change="getData" v-model="sign_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">-->
        <!--        </el-date-picker>-->

        <el-date-picker  @change="changeDate" size="small"
      v-model="year_week"
      type="month"
      value-format="yyyy-MM"
      placeholder="选择月">
    </el-date-picker>
        <el-button v-if="$store.state.pmid == 214 || $store.state.pmid == 221"
                   icon="el-icon-download"
                   size="small"
                   type="primary"
                   @click="exportXF" style="margin-left: 10px;">导出</el-button>


      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>数据统计</el-breadcrumb-item>
          <el-breadcrumb-item>巡访统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent" style="width: 100%;height: calc( 100% - 80px );">
      <div id="chartBox" style="width: 100%;height: 400px;"></div>
      <div id="chartBox1" style="width: 100%;margin-top: 50px;">
         <el-table v-tableFit :data="tableData" style="width: 100%;margin-bottom: 20px;" height="74vh" border stripe>
          <el-table-column prop="loginname" label="工号" width="" align="center"/>

          <el-table-column prop="username" label="姓名" width="200"/>
          <el-table-column prop="class_name" label="班级" width="200"/>

          <el-table-column prop="count_number" label="总次数" width="" align="center"></el-table-column>

          <el-table-column prop="count_people" label="总人次" width="" align="center"></el-table-column>
          <el-table-column prop="visit_dh" label="电话巡访" width="" align="center"></el-table-column>
          <el-table-column prop="visit_sp" label="视频巡访" width="" align="center"></el-table-column>
          <el-table-column prop="visit_sd" label="实地巡访" width="" align="center"></el-table-column>

          <!--          <el-table-column v-for="(item,index) in sxqxArr" :prop="'count_'+item.param_value" :label="item.param_desc" width="" align="center">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span>{{scope.row['count_'+item.param_value]}}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
      </div>

    </div>



  </div>
</template>

<script>
import util from "../../../utils/util.js"
import tchtree from '../../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";
export default {
  components: {
		tchtree
	},
  data() {
    return {
      myClasses: [],
      class_id: "",
      sign_date:util.formatDate(new Date()),
      dataList:[],
      dataList1:[],
      year_week:'',
      report_year:'',
      week:'',
      tableData:[],
    }
  },
  mounted() {

    this.getMyClasses()
  },
  methods: {
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        //this.class_id = this.myClasses[0].id
       this.getNowDate()
      })
    },
    getNowDate(){
      this.$http.post("/api/find_date").then(d => {
        this.report_year = d.data.year
        this.week = d.data.week
        this.year_week =util.formatDate()
        this.getData()
       this.getData1()
      })
    },
    changeDate(e){
      let str= this.$moment(e).utcOffset(480).format("yyyy-WW")

      let arr = str.split('-')
      this.report_year = arr[0]
      this.week = arr[1]
      this.getData()
      this.getData1()
    },
    getData() {
      this.$http.post("/api/stu_visit_statistics",{visit_year:this.year_week.split('-')[0],visit_month:this.year_week.split('-')[1],class_id:this.class_id}).then(res => {
        res.data&& res.data.map(a=>{
          if(a.xf_rate){
            a.xf_rate = parseFloat(a.xf_rate)*100
          }
        })
        this.dataList = res.data
        this.dataList.map(d => {
          d.username = d.NAME + d.username
        })
        this.initChart()
      })
    },
    getData1() {
      this.$http.post("/api/vist_statistics", {visit_year:this.year_week.split('-')[0],visit_month:this.year_week.split('-')[1],class_id:this.class_id}).then(res => {
        this.tableData = res.data
      })
    },
    initChart(){
      let dataAxis = this._lo.map( this.dataList, 'username')
      let data = this._lo.map( this.dataList, 'xf_rate')


      let option = {
        title: {
          text: '巡访率(%)',
        },
        grid: {containLabel: true,},
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            ////console.log(params);
            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                  result +=`<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },

        },
        xAxis: {
          data: dataAxis,
          axisLabel: {inside: false, color: '#000', alignWithLabel: true, rotate: 60},
          axisTick: {show: true},
          axisLine: {show: true},
          z: 10,
          
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: {show: true},
          axisTick: {show: true},
          axisLabel: {color: '#000'}
        },
        series: [
          {
            type: 'bar',
            barMaxWidth:30,
            itemStyle: {
                            color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                              color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              // formatter:function(params){ //标签内容
              //   return  params.value + '%'
              // },
            },

          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)

    },
    exportXF() {
      this.$confirm('默认导出当月数据，导出其他月数据，请选择月', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/export_statistics_xls', {
          visit_year:this.year_week.split('-')[0],
          visit_month:this.year_week.split('-')[1],
          class_id:this.class_id,
          type: 'xf'
        }).then(res => {
          let excelData = [
            ['班主任巡访次数汇总'],
            ['序号','时间','届次','系部','班级','班主任','巡访次数']
          ]
          res.data && res.data.map((r,index) => {
            excelData.push([
              ++index,
              this.year_week,
              r.jc_name,
              r.yx_name,
              r.class_name,
              r.username,
              r.count_number,
            ])
          })
          njsyxx_statistics_export_excel(excelData,7)
        })
      }).catch(() => {
      });
    },

  },

};
</script>

